(function ($, Drupal) {

  /**
   * Update consent for Google Tag
   */
  function updateConsent() {
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      dataLayer.push(arguments);
    }

    if (Drupal.eu_cookie_compliance) {
      const acceptedCategories = Drupal.eu_cookie_compliance.getAcceptedCategories();

      gtag('consent', 'update', {
        ad_user_data: acceptedCategories.includes('advertising') ? 'granted' : 'denied',
        ad_personalization: acceptedCategories.includes('advertising') ? 'granted' : 'denied',
        ad_storage: acceptedCategories.includes('advertising') ? 'granted' : 'denied',
        analytics_storage: acceptedCategories.includes('analytics') ? 'granted' : 'denied',
      });
    }
  }

  $(document).ready(function () {
    updateConsent();

    $('body').on('click', '.eu-cookie-compliance__button--accept-all', function () {
      updateConsent();
    });
    $('body').on('click', '.eu-cookie-compliance__button--save-preferences', function () {
      updateConsent();
    });
  });
}(jQuery, Drupal));
